// composables/useAuthLoading.ts
import { ref, computed } from "vue";

// Create global state for auth loading
const isAuthLoading = ref(false);
const authLoadingTitle = ref("Loading your experience");
const authLoadingMessage = ref(
    "Please wait while we authenticate your session..."
);

export function useAuthLoading() {
    const startAuthLoading = (title?: string, message?: string) => {
        if (title) authLoadingTitle.value = title;
        if (message) authLoadingMessage.value = message;
        isAuthLoading.value = true;
    };

    const stopAuthLoading = () => {
        isAuthLoading.value = false;
    };

    return {
        isAuthLoading: computed(() => isAuthLoading.value),
        authLoadingTitle: computed(() => authLoadingTitle.value),
        authLoadingMessage: computed(() => authLoadingMessage.value),
        startAuthLoading,
        stopAuthLoading,
    };
}
